import React from 'react';
import './ServiceCard.css';
import icon1 from './images/icon1.png';
import icon2 from './images/icon2.png';
import icon3 from './images/icon3.png';
import icon4 from './images/icon4.png';
import icon5 from './images/icon5.png';
import icon6 from './images/icon6.png';

const services = [
  {
    icon: icon1,
    title: 'Digital Marketing',
    info: 'Boost your online presence with our comprehensive digital marketing services including SEO, PPC, and social media management.',
    demand: 75
  },
  {
    icon: icon2,
    title: 'Web Development',
    info: 'Create a stunning, functional website with our custom web development services. From design to deployment, we handle it all.',
    demand: 85
  },
  {
    icon: icon3,
    title: 'Software Development',
    info: 'Develop tailored software solutions to meet your unique business needs. We offer full-cycle software development services.',
    demand: 80
  },
  {
    icon: icon4,
    title: 'SEO',
    info: 'Optimize your website for search engines with our expert SEO services to increase visibility and drive organic traffic.',
    demand: 70
  },
  {
    icon: icon5,
    title: 'Social Media',
    info: 'Enhance your brand’s presence on social media platforms with our targeted social media marketing strategies.',
    demand: 65
  },
  {
    icon: icon6,
    title: 'Android/iOS Apps',
    info: 'Develop high-quality mobile applications for Android and iOS platforms to engage your audience on the go.',
    demand: 80
  }
];

const ServiceCard = ({ icon, title, info, demand }) => {
  return (
    <div className="service-card">
      <div className="service-icon-container">
        <img src={icon} alt={title} className="service-icon" />
      </div>
      <div className="service-content">
        <h2 className="service-title">{title}</h2>
        <p className="service-info">{info}</p>
        <div className="service-demand">
          <div className="demand-bar" style={{ width: `${demand}%` }}></div>
        </div>
        <div className="demand-text-container">
          <span className="demand-text">Market Demand: {demand}%</span>
        </div>
      </div>
    </div>
  );
};

const ServiceCards = () => {
  return (
    <section className="services-section">
      <h1 className="services-title">Services We Provide</h1>
      <div className="services-container">
        {services.map((service, index) => (
          <ServiceCard
            key={index}
            icon={service.icon}
            title={service.title}
            info={service.info}
            demand={service.demand}
          />
        ))}
      </div>
    </section>
  );
};

export default ServiceCards;
