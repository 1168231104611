import React, { useState } from 'react';
import styled from 'styled-components';
import { FaTimes } from 'react-icons/fa';

const PopupOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
`;

const PopupContent = styled.div`
  background: white;
  padding: 1rem;
  border-radius: 8px;
  max-width: 90%;
  width: 100%;
  position: relative;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  overflow-y: auto;
  max-height: 90vh;

  @media (min-width: 768px) {
    max-width: 500px;
    padding: 3rem;
  }
  @media (max-width: 768px){
    height: 70vh;
    padding: 2rem;
    top: 2rem;
  }
`;

const CloseIcon = styled(FaTimes)`
  position: absolute;
  top: 1rem;
  right: 1rem;
  cursor: pointer;
  color: #333;
  font-size: 1.5rem;
`;

const Title = styled.h2`
  color: black;
  margin-bottom: 1rem;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 1rem;

  @media (min-width: 768px) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1rem;
  }
`;

const FullWidth = styled.div`
  grid-column: span 2;
`;

const Label = styled.label`
  color: black;
  text-align: left;
`;

const Input = styled.input`
  padding: 0.5rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 100%;
`;

const Select = styled.select`
  padding: 0.5rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 100%;
`;

const Button = styled.button`
  background: #ff6347;
  color: white;
  padding: 0.5rem 1rem;
  border: none;
  cursor: pointer;
  grid-column: span 2;

  &:hover {
    background: #ff4500;
  }
`;

const SuccessMessage = styled.div`
  text-align: center;
  padding: 1rem;
`;

const InquiryFormPopup = ({ onClose }) => {
  const [formStatus, setFormStatus] = useState('');

  const handleSubmit = async (event) => {
    event.preventDefault();

    const form = event.target;
    const formData = new FormData(form);
    const data = Object.fromEntries(formData.entries());

    try {
      const response = await fetch('https://formspree.io/f/mzzpgkok', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      });

      if (response.ok) {
        setFormStatus('success');
        form.reset();
      } else {
        setFormStatus('error');
      }
    } catch (error) {
      setFormStatus('error');
    }
  };

  return (
    <PopupOverlay>
      <PopupContent>
        <CloseIcon onClick={onClose} />
        {formStatus === 'success' ? (
          <SuccessMessage>
            <Title>Thank you!</Title>
            <p>Your inquiry has been submitted successfully.</p>
            <Button onClick={onClose}>Close</Button>
          </SuccessMessage>
        ) : (
          <>
            <Title>Service Inquiry</Title>
            <Form onSubmit={handleSubmit}>
              <Label htmlFor="name">Name</Label>
              <Input type="text" id="name" name="name" required />
              
              <Label htmlFor="company">Company</Label>
              <Input type="text" id="company" name="company" />
              
              <Label htmlFor="website">Website</Label>
              <Input type="url" id="website" name="website" />
              
              <Label htmlFor="email">Email</Label>
              <Input type="email" id="email" name="email" required />
              
              <Label htmlFor="contact">Contact</Label>
              <Input type="tel" id="contact" name="contact" required />
              
              <Label htmlFor="country">Country</Label>
              <Select id="country" name="country" required>
                <option value="">Select Country</option>
                <option value="USA">USA</option>
                <option value="UK">UK</option>
                <option value="Canada">Canada</option>
                <option value="Australia">Australia</option>
                <option value="India">India</option>
              </Select>
              
              <FullWidth>
                <Label htmlFor="message">Message</Label>
                <Input type="text" id="message" name="message" placeholder="Enter your message here" required />
              </FullWidth>
              
              <Button type="submit">Submit Inquiry</Button>
            </Form>
            {formStatus === 'error' && <p>Oops! There was a problem with your submission.</p>}
          </>
        )}
      </PopupContent>
    </PopupOverlay>
  );
};

export default InquiryFormPopup;
