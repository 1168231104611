import React, { useState } from 'react';
import './SeoPlan.css';

const plans = [
    {
        title: "Starter",
        features: [
            "Basic SEO Optimization for Up to 5 Pages",
            "Initial Social Media Setup",
            "Content Strategy Consultation",
            "Email Support"
        ],
        price: "$99",
        frequency: "Month",
        // buttonLabel: "Choose Plan",
        popular: false
    },
    {
        title: "Pro",
        features: [
            "Advanced SEO Strategies for Up to 15 Pages",
            "Ongoing Social Media Management",
            "Content Development Support",
            "Priority Email & Chat Support"
        ],
        price: "$249",
        frequency: "Month",
        // buttonLabel: "Choose Plan",
        popular: true
    },
    {
        title: "Enterprise",
        features: [
            "Full-Service SEO Optimization for Up to 30 Pages",
            "Dedicated Social Media Manager",
            "Custom Content Marketing Strategy",
            "24/7 Phone, Email & Chat Support"
        ],
        price: "$599",
        frequency: "Month",
        // buttonLabel: "Choose Plan",
        popular: false
    }
];


const SeoPlan = () => {
    const [zoomedPlanIndex, setZoomedPlanIndex] = useState(1); // Default zoom on the center plan

    return (
        <div className="pricing-plan">
            <div className="pricing-container">
                <div className="pricing-header">
                    <p className="caption">Choose the Best Plan for Your Needs</p>
                    <h2>Unbeatable Pricing - Find Your Perfect SEO Plan Now</h2>
                </div>
                <div className="plans">
                    {plans.map((plan, index) => (
                        <div 
                            key={index} 
                            className={`plan ${plan.popular ? 'popular' : ''} ${zoomedPlanIndex === index ? 'zoomed' : ''}`}
                            onClick={() => setZoomedPlanIndex(index)}
                        >
                            {plan.popular && <div className="ribbon">Popular</div>}
                            <h3>{plan.title}</h3>
                            <ul>
                                {plan.features.map((feature, idx) => (
                                    <li key={idx}>{feature}</li>
                                ))}
                            </ul>
                            <div className="price">
                                <span>{plan.price}</span><span>/{plan.frequency}</span>
                            </div>
                            {/* <button>{plan.buttonLabel}</button> */}
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default SeoPlan;
