import React from 'react';
import './SlideText.css';

const SlideText = () => {
  return (
    <div className="slide-text-container">
      <div className="slide-text-content">
        <div className="slide-text">
          <span>Digital Marketing</span>
          <span>SEO</span>
          <span>Social Media</span>
          <span>Content Marketing</span>
          <span>PPC</span>
          <span>Web Development</span>
          <span>Software Development</span>
          <span>UI/UX Design</span>
          <span>App Development</span>
          <span>Growth Hacking</span>
        </div>
      </div>
    </div>
  );
};

export default SlideText;
