import React, { useEffect, useState, useRef } from 'react';
import './OurProcess.css';
import icon7 from './images/icon7.png';
import icon8 from './images/icon8.png';
import icon9 from './images/icon9.png';
import icon10 from './images/icon10.png';

const steps = [
    {
        title: "Research",
        description: "Conducting thorough research to identify market trends and customer needs.",
        icon: icon7,
        color: "orange"
    },
    {
        title: "Customize",
        description: "Creating tailored solutions to meet specific business requirements.",
        icon: icon8,
        color: "blue"
    },
    {
        title: "Targeting",
        description: "Implementing targeted strategies to reach the right audience.",
        icon: icon9,
        color: "orange"
    },
    {
        title: "Result",
        description: "Delivering measurable results and continuous improvement.",
        icon: icon10,
        color: "blue"
    }
];

const OurProcess = () => {
    const [currentStep, setCurrentStep] = useState(0);
    const processRef = useRef(null);

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentStep((prevStep) => (prevStep + 1) % steps.length);
        }, 2000);

        return () => clearInterval(interval);
    }, []);

    useEffect(() => {
        if (processRef.current) {
            const stepWidth = processRef.current.querySelector('.process-step').offsetWidth;
            processRef.current.scrollTo({
                left: stepWidth * currentStep,
                behavior: 'smooth'
            });
        }
    }, [currentStep]);

    return (
        <div className="our-process" ref={processRef}>
            {steps.map((step, index) => (
                <div key={index} className="process-step">
                    <div className={`process-icon ${step.color}`}>
                        <img src={step.icon} alt={`${step.title} icon`} />
                        <div className="rotating-circle"></div>
                    </div>
                    <h3>{step.title}</h3>
                    <p>{step.description}</p>
                    <div className={`step-number ${step.color}`}>STEP {index + 1}</div>
                </div>
            ))}
        </div>
    );
};

export default OurProcess;
