import React from "react";
import { Link } from "react-router-dom";
import "./AboutUs.css";
import aboutImage from "../components/images/about.jpg"; // Update with the correct path to your image

const AboutUs = () => {
  return (
    <section className="about-us">
      <div className="about-us-content">
        <div className="about-us-image-container">
          <div className="background-shape"></div>
          <img src={aboutImage} alt="About Us" className="about-us-image" />
        </div>
        <div className="about-us-text">
          <h1>About Us: 1 Digit Technology</h1>
          <p>
            1 Digit Technology is a dynamic digital marketing and development firm dedicated to driving business growth through innovative digital solutions. Our team of experts specializes in creating impactful marketing strategies and cutting-edge development projects that elevate your online presence and deliver measurable results. At 1 Digit Technology, we are committed to transforming your digital landscape and helping you achieve your business goals with precision and efficiency.
          </p>
          <ul className="features-list">
            <li>Expertise in innovative digital marketing strategies</li>
            <li>Cutting-edge web and app development services</li>
            <li>Focused on delivering measurable results</li>
            <li>Committed to transforming your digital landscape</li>
          </ul>
          <Link to="/about" className="cta-button">Discover More</Link> {/* Updated to Link */}
        </div>
      </div>
    </section>
  );
};

export default AboutUs;
