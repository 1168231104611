import React, { useState } from 'react';
import './About.css';

// Importing images
import missionImage from './images/mission.png';
import visionImage from './images/vision.png';

const About = () => {
  const [formState, setFormState] = useState({
    name: '',
    email: '',
    message: '',
  });
  const [successMessage, setSuccessMessage] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsSubmitting(true);

    const formData = new FormData();
    formData.append('name', formState.name);
    formData.append('email', formState.email);
    formData.append('message', formState.message);

    try {
      const response = await fetch('https://formspree.io/f/mzzpgkok', {
        method: 'POST',
        body: formData,
        headers: {
          'Accept': 'application/json',
        },
      });

      if (response.ok) {
        setSuccessMessage('Thank you for your message! We will get back to you soon.');
        setFormState({ name: '', email: '', message: '' }); // Clear the form fields
      } else {
        setSuccessMessage('Something went wrong. Please try again.');
      }
    } catch (error) {
      setSuccessMessage('Something went wrong. Please try again.');
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="about-container">
      {/* Hero Section */}
      <section className="hero-section">
        <div className='header'>
          <h1>About Us</h1>
          <p>We are a leading digital marketing and development company, 1 Digit Technology.</p>
        </div>
      </section>

      {/* Company Information Section */}
      <section className="company-info-section">
        <h2>Our Company</h2>
        <p>
          We specialize in providing top-notch digital marketing strategies and custom development solutions that help businesses grow
          online. Our team is passionate about leveraging the latest technologies and creative strategies to deliver results that
          exceed our clients' expectations.
        </p>
        <ul>
          <li>Innovative Digital Marketing Strategies</li>
          <li>Custom Development Solutions</li>
          <li>Passionate and Experienced Team</li>
          <li>Results-Driven Approach</li>
        </ul>
      </section>

      {/* Mission and Vision Section */}
      <section className="mission-vision-section">
        <div className="mission-vision-content">
          <div className="mission-vision-wrapper">
            <div className="mission">
              <img src={missionImage} alt="Mission" />
              <div className="mission-content">
                <h3>Mission</h3>
                <p>
                  Our mission is to empower businesses to achieve their goals through innovative digital marketing strategies and
                  cutting-edge development solutions. We aim to provide exceptional service and deliver tangible results that drive
                  growth and success for our clients.
                </p>
              </div>
            </div>
            <div className="vision">
              <div className="vision-content">
                <h3>Vision</h3>
                <p>
                  Our vision is to become the leading digital marketing and development company globally, recognized for our commitment
                  to innovation, quality, and client satisfaction. We strive to be at the forefront of the digital revolution, helping
                  businesses navigate the ever-changing landscape of technology and marketing.
                </p>
              </div>
              <img src={visionImage} alt="Vision" />
            </div>
          </div>
        </div>
      </section>

      {/* Why Choose Us Section */}
      <section className="why-choose-us-section">
        <h2>Why Choose Us?</h2>
        <div className="choose-us-wrapper">
          <div className="choose-us-item">
            <div className="icon-container">
              <i className="fas fa-users"></i>
            </div>
            <h3>Expert Team</h3>
            <p>
              Our team consists of industry experts with years of experience in digital marketing and development. We are passionate
              about what we do and are dedicated to delivering the best results for our clients.
            </p>
          </div>
          <div className="choose-us-item">
            <div className="icon-container">
              <i className="fas fa-cogs"></i>
            </div>
            <h3>Customized Solutions</h3>
            <p>
              We understand that every business is unique, which is why we offer customized solutions tailored to your specific needs
              and goals. Whether you need a full-scale digital marketing strategy or a custom web development project, we’ve got you
              covered.
            </p>
          </div>
          <div className="choose-us-item">
            <div className="icon-container">
              <i className="fas fa-chart-line"></i>
            </div>
            <h3>Proven Results</h3>
            <p>
              We are committed to delivering results that drive growth and success for our clients. Our track record speaks for itself,
              with numerous successful campaigns and satisfied clients who have seen significant improvements in their business
              performance.
            </p>
          </div>
        </div>
      </section>

      {/* Contact Form Section */}
      <section className="contact-form-section">
        <div className="contact-form-content">
          <h2>Get in Touch</h2>
          <form onSubmit={handleSubmit} className="contact-form">
            <input
              type="text"
              name="name"
              value={formState.name}
              onChange={handleChange}
              placeholder="Your Name"
              required
            />
            <input
              type="email"
              name="email"
              value={formState.email}
              onChange={handleChange}
              placeholder="Your Email"
              required
            />
            <textarea
              name="message"
              value={formState.message}
              onChange={handleChange}
              placeholder="Your Message"
              required
            ></textarea>
            <button type="submit" disabled={isSubmitting}>
              {isSubmitting ? 'Sending...' : 'Send Message'}
            </button>
          </form>
          {successMessage && <p className="success-message">{successMessage}</p>}
        </div>
      </section>
    </div>
  );
};

export default About;
