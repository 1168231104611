import React from "react";
import { Link } from "react-router-dom"; // Import Link for navigation
import "./Skills.css";
import skillsImage from "../components/images/skills.jpg"; // Update with the correct path to your image

const Skills = () => {
  return (
    <section className="skills-section">
      <div className="skills-content">
        <div className="skills-image-container">
          <div className="skills-background-shape"></div>
          <img src={skillsImage} alt="Our Skills" className="skills-image" />
        </div>
        <div className="skills-text">
          <h1>Our Skills & Expertise</h1>
          <p>
            At 1 Digit Technology, our team is equipped with a diverse set of skills and expertise to help you succeed in the digital space. From innovative digital marketing strategies to cutting-edge web and app development, we deliver results that make a difference.
          </p>
          <ul className="skills-features-list">
            <li>Proficiency in digital marketing and SEO</li>
            <li>Advanced web and mobile app development</li>
            <li>Comprehensive analytics and reporting</li>
            <li>Commitment to ongoing innovation and improvement</li>
          </ul>
          <Link to="/contact" className="skills-cta-button">Connect Now</Link> {/* Updated to Link */}
        </div>
      </div>
    </section>
  );
};

export default Skills;
