import React, { useState } from 'react';
import './Services.css';

// Import images from the src/images directory
import webDevelopment from '../images/web-development.png';
import eCommerce from '../images/e-commerce.png';
import seo from '../images/seo.png';
import digitalMarketing from '../images/digital-marketing.png';
import mobileApp from '../images/mobile-app.png';
import uiUx from '../images/ui-ux.png';
import contentCreation from '../images/content-creation.png';
import socialMedia from '../images/social-media.png';
import branding from '../images/branding.png';
import graphicDesign from '../images/graphic-design.png';
import consulting from '../images/consulting.png';
import dataAnalysis from '../images/data-analysis.png';
import customSoftware from '../images/custom-software.png';
import cloudServices from '../images/cloud-services.png';
import cybersecurity from '../images/cybersecurity.png';
import innovation from '../images/innovation.png';
import inquiryImage from '../images/inquiry.png';
import gallery1 from '../images/gallery-image1.png';
import gallery2 from '../images/gallery-image2.png';
import gallery3 from '../images/gallery-image3.png';
import gallery4 from '../images/gallery-image4.png';
import gallery5 from '../images/gallery-image5.png';
import gallery6 from '../images/gallery-image6.png';

const Services = () => {
  const [formStatus, setFormStatus] = useState('');

  const services = [
    { title: "Web Development", imgSrc: webDevelopment, description: "Creating responsive and functional websites tailored to your needs." },
    { title: "E-commerce Solutions", imgSrc: eCommerce, description: "Developing online stores with seamless shopping experiences." },
    { title: "SEO Services", imgSrc: seo, description: "Optimizing your website to rank higher on search engines." },
    { title: "Digital Marketing", imgSrc: digitalMarketing, description: "Promoting your brand online through various digital channels." },
    { title: "Mobile App Development", imgSrc: mobileApp, description: "Building user-friendly mobile applications for Android and iOS." },
    { title: "UI/UX Design", imgSrc: uiUx, description: "Designing intuitive interfaces and engaging user experiences." },
    { title: "Content Creation", imgSrc: contentCreation, description: "Creating compelling content that resonates with your audience." },
    { title: "Social Media Management", imgSrc: socialMedia, description: "Managing and growing your social media presence." },
    { title: "Branding", imgSrc: branding, description: "Building and developing your brand identity." },
    { title: "Graphic Design", imgSrc: graphicDesign, description: "Creating visually appealing graphics for your business." },
    { title: "Consulting", imgSrc: consulting, description: "Providing expert advice to help grow your business." },
    { title: "Data Analysis", imgSrc: dataAnalysis, description: "Analyzing data to provide actionable insights." },
    { title: "Custom Software", imgSrc: customSoftware, description: "Developing software solutions tailored to your business needs." },
    { title: "Cloud Services", imgSrc: cloudServices, description: "Offering scalable and secure cloud solutions." },
    { title: "Cybersecurity", imgSrc: cybersecurity, description: "Protecting your digital assets from cyber threats." },
    { title: "Innovation", imgSrc: innovation, description: "Using the latest technologies and innovative approaches to keep you ahead of the competition." }
  ];

  const galleryImages = [gallery1, gallery2, gallery3, gallery4, gallery5, gallery6];

  const handleSubmit = async (event) => {
    event.preventDefault(); // Prevent the default form submission

    const formData = new FormData(event.target);

    try {
      const response = await fetch('https://formspree.io/f/mzzpgkok', {
        method: 'POST',
        body: formData,
        headers: {
          'Accept': 'application/json'
        }
      });

      if (response.ok) {
        setFormStatus('Thank you, your form has been submitted successfully.');
        event.target.reset(); // Clear the form
      } else {
        setFormStatus('Oops, something went wrong. Please try again.');
      }
    } catch (error) {
      setFormStatus('Oops, something went wrong. Please try again.');
    }
  };

  return (
    <div>
      {/* Service Cards Section */}
      <section className="service-cards-section">
        <div className='header'>
          <h1 className="section-title">Our Services</h1>
          <p className="subtitle">We offer a wide range of services to meet your needs.</p>
        </div>
        <div className="service-cards">
          {services.map((service, index) => (
            <div key={index} className="service-card1">
              <img src={service.imgSrc} alt={service.title} />
              <h3>{service.title}</h3>
              <p>{service.description}</p>
            </div>
          ))}
        </div>
      </section>

      {/* Gallery Section */}
      <section className="gallery-section">
        <h2>Our Work Gallery</h2>
        <div className="gallery">
          {galleryImages.map((imgSrc, index) => (
            <div key={index} className="gallery-item">
              <img src={imgSrc} alt={`Gallery ${index + 1}`} />
            </div>
          ))}
        </div>
      </section>

      {/* Inquiry Section */}
      <section className="inquiry-section">
        <div className="inquiry-left">
          <img src={inquiryImage} alt="Inquiry" />
        </div>
        <div className="inquiry-right">
          <h2>Request a Service</h2>
          <form onSubmit={handleSubmit}>
            <input type="text" name="name" placeholder="Your Name" required />
            <input type="email" name="email" placeholder="Your Email" required />
            <input type="text" name="company" placeholder="Company" />
            <input type="text" name="service" placeholder="Service Required" />
            <input type="text" name="countryCode" placeholder="Country Code (e.g., +1)" />
            <input type="text" name="contactNumber" placeholder="Contact Number" />
            <input type="text" name="country" placeholder="Country (e.g., USA)" />
            <textarea name="message" placeholder="Describe your needs" required></textarea>
            <button type="submit">Submit Inquiry</button>
            {formStatus && <p className="form-status">{formStatus}</p>}
          </form>
        </div>
      </section>

      {/* Why Choose Us Section */}
      <section className="why-choose-us-section">
        <h2>Why Choose Us?</h2>
        <p className="intro-text">We deliver top-notch services that help your business succeed.</p>
        <div className="reasons">
          <div className="reason-card">
            <h3>Experience</h3>
            <p>With years of experience, we bring expertise to every project.</p>
          </div>
          <div className="reason-card">
            <h3>Quality</h3>
            <p>We ensure the highest quality in every aspect of our work.</p>
          </div>
          <div className="reason-card">
            <h3>Support</h3>
            <p>We offer continuous support to help your business thrive.</p>
          </div>
          <div className="reason-card">
            <h3>Innovation</h3>
            <p>We use the latest technologies and innovative approaches to keep you ahead of the competition.</p>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Services;
