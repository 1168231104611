import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './components/Header';
import Hero from './components/Hero';
import Footer from './components/Footer';
import FooterAttribute from './components/FooterAttribute';
import Services from './pages/Services';
import About from './pages/About';
import Contact from './pages/Contact';
import AboutUs from './components/AboutUs';
import ServiceCard from './components/ServiceCard';
import SlideText from './components/SlideText';
import Skills from './components/Skills';
import OurProcess from './components/OurProcess';
import Technologies from './components/Technologies';
import SeoPlan from './components/SeoPlan';

const App = () => {
  return (
    <Router>
      <Header />
      <Routes>
        <Route 
          path="/" 
          element={
            <>
              <Hero />
              <SlideText />
              <AboutUs />
              <ServiceCard />
              <Skills />
              <OurProcess />
              <Technologies />
              <SeoPlan />
            </>
          } 
        />
        <Route path="/services" element={<Services />} />
        <Route path="/about" element={<About />} />
        <Route path="/contact" element={<Contact />} />
      </Routes>
      <Footer />
      <FooterAttribute />
    </Router>
  );
};

export default App;
