import React, { useState, useRef, useEffect } from 'react';
import './Contact.css';

// Image imports
import digitalMarketingImg from '../images/digital.png';
import webDesignImg from '../images/web.png';
import softwareDevelopmentImg from '../images/software.png';

const Contact = () => {
    const [faqOpen, setFaqOpen] = useState(null);
    const faqRefs = useRef([]);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (faqOpen !== null && faqRefs.current.every(ref => !ref.contains(event.target))) {
                setFaqOpen(null);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => document.removeEventListener('mousedown', handleClickOutside);
    }, [faqOpen]);

    const toggleFaq = (index) => {
        setFaqOpen(faqOpen === index ? null : index);
    };

    const faqs = [
        {
            question: "What is digital marketing and how can it benefit my business?",
            answer: "Digital marketing involves promoting products or services through digital channels. It can help increase your online visibility, reach a larger audience, and drive more sales."
        },
        {
            question: "What services are included in your digital marketing package?",
            answer: "Our digital marketing package includes SEO, social media marketing, content marketing, email marketing, and PPC advertising."
        },
        {
            question: "How long does it take to see results from SEO?",
            answer: "SEO is a long-term strategy. Typically, you can expect to see noticeable results within 3 to 6 months, depending on your industry and competition."
        },
        {
            question: "Can you help with social media management?",
            answer: "Yes, we offer comprehensive social media management services, including content creation, posting, and engagement with your audience."
        },
        {
            question: "What is the difference between organic and paid search?",
            answer: "Organic search results are earned through SEO, while paid search results come from PPC ads. Both are important for a well-rounded digital marketing strategy."
        },
        {
            question: "How do you measure the success of a digital marketing campaign?",
            answer: "We use various metrics such as website traffic, conversion rates, click-through rates, and return on investment (ROI) to measure success."
        },
        {
            question: "What types of websites do you design and develop?",
            answer: "We design and develop various types of websites, including corporate sites, e-commerce stores, blogs, and custom web applications."
        },
        {
            question: "How do you ensure my website is mobile-friendly?",
            answer: "We use responsive design techniques to ensure your website looks and functions well on all devices, including smartphones and tablets."
        },
        {
            question: "Can you redesign my existing website?",
            answer: "Yes, we can redesign your website to improve its appearance, functionality, and performance."
        },
        {
            question: "What is included in your website maintenance services?",
            answer: "Our website maintenance services include regular updates, security monitoring, backups, and technical support to keep your site running smoothly."
        },
        {
            question: "How long does it take to build a custom website?",
            answer: "The timeline depends on the complexity of the project. A simple website may take 4-6 weeks, while more complex projects can take several months."
        },
        {
            question: "Do you provide e-commerce solutions?",
            answer: "Yes, we offer comprehensive e-commerce solutions, including store setup, payment gateway integration, and ongoing support."
        },
        {
            question: "Can you help with content creation for my website?",
            answer: "Absolutely! We have a team of skilled content writers who can create engaging and SEO-friendly content for your website."
        },
        {
            question: "What programming languages and frameworks do you use?",
            answer: "We use a variety of languages and frameworks, including HTML, CSS, JavaScript, React, Node.js, and Python, depending on the project requirements."
        },
        {
            question: "How do you handle website security?",
            answer: "We implement robust security measures such as SSL certificates, regular security updates, and monitoring to protect your website from threats."
        },
        {
            question: "What is custom software development?",
            answer: "Custom software development involves creating software solutions tailored to your specific business needs, ensuring a perfect fit for your operations."
        },
        {
            question: "Can you integrate third-party APIs into my software?",
            answer: "Yes, we can integrate a variety of third-party APIs to extend the functionality of your software and streamline your business processes."
        },
        {
            question: "What is your process for software development?",
            answer: "Our software development process includes requirement analysis, design, development, testing, deployment, and ongoing support."
        },
        {
            question: "Do you offer support after the software is launched?",
            answer: "Yes, we offer post-launch support and maintenance to ensure your software continues to meet your needs and operates smoothly."
        },
        {
            question: "How do you ensure my project stays on schedule?",
            answer: "We use project management tools and methodologies to track progress, communicate with you regularly, and ensure timely delivery of your project."
        }
    ];

    const handleSubmit = async (event) => {
        event.preventDefault();
        const formData = new FormData(event.target);
        const data = {
            name: formData.get('name'),
            email: formData.get('email'),
            subject: formData.get('subject'),
            message: formData.get('message')
        };

        try {
            const response = await fetch('/api/contact', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(data),
            });

            if (response.ok) {
                alert('Your message has been sent successfully!');
                event.target.reset();
            } else {
                alert('There was a problem sending your message. Please try again later.');
            }
        } catch (error) {
            console.error('Error:', error);
            alert('There was a problem sending your message. Please try again later.');
        }
    };

    return (
        <div className="contact-page">
            <header className="header">
                <div className="header-content">
                    <h1>Contact Us</h1>
                    <p>Let's work together to build something great. Reach out to us today!</p>
                </div>
            </header>

            <section className="contact-info">
                <div className="info-item">
                    <img src={digitalMarketingImg} alt="Digital Marketing" />
                    <h2>Digital Marketing</h2>
                    <p>Enhance your online presence with our expert digital marketing services.</p>
                </div>
                <div className="info-item">
                    <img src={webDesignImg} alt="Website Design" />
                    <h2>Website Design</h2>
                    <p>Create stunning and responsive websites that captivate your audience.</p>
                </div>
                <div className="info-item">
                    <img src={softwareDevelopmentImg} alt="Software Development" />
                    <h2>Software Development</h2>
                    <p>Custom software and application development tailored to your business needs.</p>
                </div>
            </section>

            <section className="contact-form-section">
                <h2>Get in Touch</h2>
                <form className="contact-form" onSubmit={handleSubmit}>
                    <div className="form-group">
                        <label htmlFor="name">Name</label>
                        <input type="text" id="name" name="name" required />
                    </div>
                    <div className="form-group">
                        <label htmlFor="email">Email</label>
                        <input type="email" id="email" name="email" required />
                    </div>
                    <div className="form-group">
                        <label htmlFor="subject">Subject</label>
                        <input type="text" id="subject" name="subject" required />
                    </div>
                    <div className="form-group">
                        <label htmlFor="message">Message</label>
                        <textarea id="message" name="message" rows="5" required></textarea>
                    </div>
                    <button type="submit" className="submit-btn">Send Message</button>
                </form>
            </section>

            <section className="faq-section">
                <h2>Frequently Asked Questions</h2>
                <div className="faqs">
                    <div className="faq-column">
                        {faqs.slice(0, 10).map((faq, index) => (
                            <div key={index} className="faq-item" ref={(el) => (faqRefs.current[index] = el)}>
                                <button className="faq-question" onClick={() => toggleFaq(index)}>
                                    <span>{faq.question}</span>
                                    <span className={`faq-arrow ${faqOpen === index ? 'open' : ''}`}></span>
                                </button>
                                <div className={`faq-answer ${faqOpen === index ? 'show' : ''}`}>
                                    <p>{faq.answer}</p>
                                </div>
                            </div>
                        ))}
                    </div>
                    <div className="faq-column">
                        {faqs.slice(10).map((faq, index) => (
                            <div key={index + 10} className="faq-item" ref={(el) => (faqRefs.current[index + 10] = el)}>
                                <button className="faq-question" onClick={() => toggleFaq(index + 10)}>
                                    <span>{faq.question}</span>
                                    <span className={`faq-arrow ${faqOpen === index + 10 ? 'open' : ''}`}></span>
                                </button>
                                <div className={`faq-answer ${faqOpen === index + 10 ? 'show' : ''}`}>
                                    <p>{faq.answer}</p>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </section>
        </div>
    );
};

export default Contact;
