import React, { useEffect, useState } from 'react';
import styled, { keyframes, css } from 'styled-components';
import desktopBanner from '../images/desktopBanner.jpg';
import mobileBanner from '../images/mobileBanner.jpg';

// Styled components
const HeroSection = styled.section`
  position: relative;
  text-align: center;
  color: white;
  height: 100vh;
  z-index: 0;
  background-image: url(${(props) => props.bgImage});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
`;

const HeroText = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  z-index: 1;
`;

const HeroTitle = styled.h1`
  font-size: 3rem;
  margin: 0;

  @media (max-width: 475px) and (min-width: 325px) {
    font-size: 2rem;
  }
`;

const directions = {
  top: keyframes`
    0% { opacity: 0; transform: translateY(-100%); }
    100% { opacity: 1; transform: translateY(0); }
  `,
  bottom: keyframes`
    0% { opacity: 0; transform: translateY(100%); }
    100% { opacity: 1; transform: translateY(0); }
  `,
  left: keyframes`
    0% { opacity: 0; transform: translateX(-100%); }
    100% { opacity: 1; transform: translateX(0); }
  `,
  right: keyframes`
    0% { opacity: 0; transform: translateX(100%); }
    100% { opacity: 1; transform: translateX(0); }
  `,
};

const underlineColors = ['#ff6347', '#ff4500', '#FFD700', '#00BFFF', '#32CD32'];

const DynamicWord = styled.span`
  display: inline-block;
  color: #ff6347;
  font-size: 3.5rem;
  margin-left: 0.5rem;
  border-bottom: 3px solid ${(props) => props.underlineColor};
  ${(props) => css`
    animation: ${directions[props.direction]} 0.8s ease-in-out;
  `}

  @media (max-width: 475px) and (min-width: 325px) {
    font-size: 2.5rem;
  }
`;

const ScrollDownIcon = styled.div`
  margin-top: 2rem;
  cursor: pointer;
  font-size: 2rem;
  color: white;
  transition: color 0.3s ease-in-out;
  
  &:hover {
    color: #ff6347;
    cursor: pointer;
  }
`;

const ExploreButton = styled.a`
  display: inline-block;
  margin-top: 1rem;
  padding: 0.75rem 1.5rem;
  font-size: 1.2rem;
  color: white;
  background-color: #ff6347;
  text-decoration: none;
  border-radius: 5px;
  transition: background-color 0.3s ease-in-out;
  border: 2px solid #fff; /* Temporary border for visibility */
  
  &:hover {
    background-color: #ff4500;
  }
`;

// Words for DynamicWord
const words = ["MARKETING", "ADVERTISING", "SOFTWARE", "APPLICATIONS", "ANDROID/IOS"];

const Hero = () => {
  const [currentWordIndex, setCurrentWordIndex] = useState(0);
  const [direction, setDirection] = useState('top');
  const [underlineColor, setUnderlineColor] = useState(underlineColors[0]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      const newIndex = (currentWordIndex + 1) % words.length;
      const randomDirection = Object.keys(directions)[Math.floor(Math.random() * 4)];
      const randomColor = underlineColors[Math.floor(Math.random() * underlineColors.length)];
      
      setDirection(randomDirection);
      setUnderlineColor(randomColor);
      setCurrentWordIndex(newIndex);
    }, 3000);

    return () => clearInterval(intervalId);
  }, [currentWordIndex]);

  const getBannerImage = () => {
    return window.innerWidth <= 475 ? mobileBanner : desktopBanner;
  };

  const [bgImage, setBgImage] = useState(getBannerImage());

  useEffect(() => {
    const handleResize = () => {
      setBgImage(getBannerImage());
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const scrollToNextSection = () => {
    const nextSection = document.querySelector('.about-us');
    
    console.log('Scrolling to:', nextSection);

    if (nextSection) {
      nextSection.scrollIntoView({ behavior: 'smooth' });
    } else {
      console.error('Element with class "about-us" not found.');
    }
  };

  return (
    <HeroSection bgImage={bgImage}>
      <HeroText>
        <HeroTitle>
          We Can Help You With
          <DynamicWord direction={direction} underlineColor={underlineColor}>
            {words[currentWordIndex]}
          </DynamicWord>
        </HeroTitle>
        <ScrollDownIcon onClick={scrollToNextSection}>
          &#9660;
        </ScrollDownIcon>
        <ExploreButton href="/services">Explore Services</ExploreButton> {/* Updated to <a> */}
      </HeroText>
    </HeroSection>
  );
};

export default Hero;
