// src/components/Footer.js
import React, { useState } from 'react';
import styled from 'styled-components';
import { FaFacebook, FaTwitter, FaLinkedin, FaInstagram } from 'react-icons/fa';
import InquiryFormPopup from './InquiryFormPopup'; // Import the popup component

// Import the logo image
import logo from '../images/1Digit-Technology.jpg';

const FooterContainer = styled.footer`
  background: #333;
  color: white;
  padding: 2rem;
  position: relative;
  overflow: hidden;
  z-index: 1;
`;

const FloatingEffect = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 20px;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0) 100%);
  z-index: 2;
`;

const FooterContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 3;
  position: relative;

  @media (min-width: 768px) {
    flex-direction: row;
    justify-content: space-between;
  }
`;

const FooterLogo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 2rem;

  @media (min-width: 768px) {
    margin-bottom: 0;
  }
`;

const Logo = styled.img`
  max-width: 150px;
  margin-bottom: 1rem; /* Add some space between logo and Inquiry button */
  border-radius: 0 50px;

  /* Media query for screen widths between 767px and 1000px */
  @media screen and (min-width: 767px) and (max-width: 1000px) {
    max-width: 110px; /* Set logo width to 100px */
    border-radius: 0 40px;
  }
`;


const SocialMedia = styled.div`
  margin: 1rem 0;
  display: flex;
  gap: 1rem;

  a {
    color: white;
    font-size: 1.5rem;
    transition: color 0.3s;

    &:hover {
      color: #ff6347;
    }
  }
`;

const EmailSignup = styled.div`
  margin: 1rem 0;
  text-align: center;

  form {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  input {
    padding: 0.5rem;
    border: none;
    border-radius: 4px;
    margin-right: 0.5rem;
  }

  button {
    background: #ff6347;
    color: white;
    border: none;
    padding: 0.5rem 1rem;
    border-radius: 4px;
    cursor: pointer;

    &:hover {
      background: #ff4500;
    }
  }

  .feedback {
    margin-top: 1rem;
    color: #ff6347;
  }

  /* Media query for screen widths between 767px and 1000px */
  @media screen and (min-width: 767px) and (max-width: 1000px) {
    form {
      flex-direction: column; /* Change direction to column */
    }

    input {
      margin-right: 0; /* Remove margin-right to align input and button vertically */
      margin-bottom: 0.5rem; /* Add margin-bottom to separate input and button */
    }
  }
`;


const FooterMenu = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 2rem;

  @media (min-width: 768px) {
    flex-direction: row;
    width: auto;
    margin-bottom: 0;
  }
    @media screen and (min-width: 767px) and (max-width: 1000px){
    font-size: 10px;
    }
`;

const FooterMenuItem = styled.div`
  position: relative;
  margin-bottom: 1rem;

  @media (min-width: 768px) {
    margin-bottom: 0;
    padding: 0 1rem;
    flex: 1;
  }
    @media screen and (min-width: 767px) and (max-width: 1000px){
    padding: 0 0.5rem;
    }
`;

const FooterMenuLink = styled.a`
  color: white;
  text-decoration: none;
  display: block;
  padding: 0.5rem;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);

  &:hover {
    background: #555;
  }

  @media (min-width: 768px) {
    border-bottom: none;
    padding: 0.5rem 0;
  }
`;

const FooterSubMenu = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  background: #444;
  display: flex;
  flex-direction: column;
  position: static;
  width: 100%;
  z-index: 1;

  @media (min-width: 768px) {
    position: static;
    background: transparent;
    display: flex;
    flex-direction: column;
    padding: 0;
    margin: 0;
  }
`;

const FooterSubMenuLink = styled.li`
  padding: 0.5rem;
  transition: background 0.3s;

  &:hover {
    background: #555;
  }
`;

const Address = styled.div`
  text-align: center;
  margin-bottom: 2rem;

  @media (min-width: 768px) {
    text-align: left;
    margin-bottom: 0;
  }
    @media screen and (min-width: 767px) and (max-width: 1000px){
    font-size: 10px;
    }
`;

const FooterContact = styled.p`
  margin: 0.5rem 0;
`;

const InquiryButton = styled.button`
  background: #ff6347;
  color: white;
  padding: 0.5rem 1rem;
  border: none;
  cursor: pointer;
  border-radius: 4px;

  &:hover {
    background: #ff4500;
  }
`;

const Footer = () => {
  const [showPopup, setShowPopup] = useState(false);
  const [formStatus, setFormStatus] = useState('');

  const togglePopup = () => {
    setShowPopup(!showPopup);
  };

  const handleFormSubmit = async (event) => {
    event.preventDefault();
    const form = event.target;
    const formData = new FormData(form);

    try {
      const response = await fetch('https://formspree.io/f/mrbzkojn', {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
        },
        body: formData,
      });

      if (response.ok) {
        setFormStatus('Thank you for subscribing!');
        form.reset();
      } else {
        setFormStatus('Oops! Something went wrong.');
      }
    } catch (error) {
      setFormStatus('Oops! Something went wrong.');
    }
  };

  return (
    <FooterContainer>
      <FloatingEffect />
      <FooterContent>
        <FooterMenu>
          <FooterMenuItem>
            <FooterMenuLink href="#services" style={{ fontWeight: 'bold' }}>Services</FooterMenuLink>
            <FooterSubMenu>
              <FooterSubMenuLink><FooterMenuLink href="#seo">SEO</FooterMenuLink></FooterSubMenuLink>
              <FooterSubMenuLink><FooterMenuLink href="#digital-marketing">Marketing</FooterMenuLink></FooterSubMenuLink>
              <FooterSubMenuLink><FooterMenuLink href="#web-development">Development</FooterMenuLink></FooterSubMenuLink>
            </FooterSubMenu>
          </FooterMenuItem>
          <FooterMenuItem>
            <FooterMenuLink href="#about" style={{ fontWeight: 'bold' }}>About</FooterMenuLink>
            <FooterSubMenu>
              <FooterSubMenuLink><FooterMenuLink href="#our-story">Story</FooterMenuLink></FooterSubMenuLink>
              <FooterSubMenuLink><FooterMenuLink href="#team">Team</FooterMenuLink></FooterSubMenuLink>
            </FooterSubMenu>
          </FooterMenuItem>
          <FooterMenuItem>
            <FooterMenuLink href="#contact" style={{ fontWeight: 'bold' }}>Contact</FooterMenuLink>
            <FooterSubMenu>
              <FooterSubMenuLink><FooterMenuLink href="#contact-us">Contact</FooterMenuLink></FooterSubMenuLink>
              <FooterSubMenuLink><FooterMenuLink href="#support">Support</FooterMenuLink></FooterSubMenuLink>
            </FooterSubMenu>
          </FooterMenuItem>
        </FooterMenu>
        <Address>
          <FooterContact>PRACHHA HOUSE, 3rd Floor,</FooterContact>
          <FooterContact> Beside Diamond City hotel, Bamanji Sheri,</FooterContact>
          <FooterContact> Rughnathpura Road, Lal Darwaja, Surat - 395003</FooterContact>
          <FooterContact>Email: contact@1digittechnology.com</FooterContact>
          <FooterContact>Phone: +91-820-095-6950</FooterContact>
        </Address>
        <FooterLogo>
          <SocialMedia>
            <a href="https://facebook.com" target="_blank" rel="noopener noreferrer"><FaFacebook /></a>
            <a href="https://twitter.com" target="_blank" rel="noopener noreferrer"><FaTwitter /></a>
            <a href="https://linkedin.com" target="_blank" rel="noopener noreferrer"><FaLinkedin /></a>
            <a href="https://instagram.com" target="_blank" rel="noopener noreferrer"><FaInstagram /></a>
          </SocialMedia>
          <EmailSignup>
            <form onSubmit={handleFormSubmit}>
              <input type="email" name="email" placeholder="Your email" required />
              <button type="submit">Subscribe</button>
              {formStatus && <div className="feedback">{formStatus}</div>}
            </form>
          </EmailSignup>
        </FooterLogo>
        <FooterLogo>
          <Logo src={logo} alt="Company Logo" />
          <InquiryButton onClick={togglePopup}>Inquiry Now</InquiryButton>
        </FooterLogo>
      </FooterContent>
      {showPopup && <InquiryFormPopup onClose={togglePopup} />}
    </FooterContainer>
  );
};

export default Footer;
