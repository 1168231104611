import React from 'react';
import './Technologies.css'; // Import the CSS file for styling

// Import images
import reactIcon from './images/react.png';
import nodeIcon from './images/node.png';
import javascriptIcon from './images/java.png';
import cssIcon from './images/css.png';
import htmlIcon from './images/html.png';
import pythonIcon from './images/python.png';

// Map technologies to their respective icons
const technologies = [
  { name: 'React', icon: reactIcon },
  { name: 'Node.js', icon: nodeIcon },
  { name: 'JavaScript', icon: javascriptIcon },
  { name: 'CSS', icon: cssIcon },
  { name: 'HTML', icon: htmlIcon },
  { name: 'Python', icon: pythonIcon },
  // Add more technologies as needed
];

const Technologies = () => {
  return (
    <section className="technologies">
      <h2 className="technologies-title">Technologies We Support</h2>
      <div className="technologies-cards">
        {technologies.map((tech) => (
          <div className="technology-card" key={tech.name}>
            <img
              src={tech.icon}
              alt={tech.name}
              className="technology-card-icon"
            />
            <h3 className="technology-card-title">{tech.name}</h3>
          </div>
        ))}
      </div>
    </section>
  );
};

export default Technologies;
